import React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"
import { graphql } from "gatsby"

export default ({ location, data }) => {
  const title = data.site.siteMetadata.title
  return (
    <div>
      <Layout location={location} title={title}>
        <Seo title="Disclaimer"/>
        <h1>Disclaimer</h1>
        <p>
          This disclaimer (&quot;Disclaimer&quot;, &quot;Agreement&quot;) is an
          agreement between Website Operator (&quot;Website Operator&quot;,
          &quot;us&quot;, &quot;we&quot; or &quot;our&quot;) and you
          (&quot;User&quot;, &quot;you&quot; or &quot;your&quot;). This
          Disclaimer sets forth the general guidelines, terms and conditions of
          your use of the{" "}
          <a target="_blank" rel="noopener noreferrer" href="https://www.devsurvival.com">
            devsurvival.com
          </a>{" "}
          website and any of its products or services (collectively,
          &quot;Website&quot; or &quot;Services&quot;).
        </p>
        <h2>Representation</h2>
        <p>
          Any views or opinions represented in this Website are personal and
          belong solely to Website Operator and do not represent those of
          people, institutions or organizations that the owner may or may not be
          associated with in professional or personal capacity unless explicitly
          stated. Any views or opinions are not intended to malign any religion,
          ethnic group, club, organization, company, or individual.
        </p>
        <h2>Content and postings</h2>
        <p>
          You may not modify, print or copy any part of the Website. Inclusion
          of any part of this Website in another work, whether in printed or
          electronic or another form or inclusion of any part of the Website in
          another website by embedding, framing or otherwise without the express
          permission of Website Operator is prohibited.{" "}
        </p>
        <p>
          You may submit comments for the Content available on the Website. By
          uploading or otherwise making available any information to Website
          Operator, you grant Website Operator the unlimited, perpetual right to
          distribute, display, publish, reproduce, reuse and copy the
          information contained therein. You may not impersonate any other
          person through the Website. You may not post content that is
          defamatory, fraudulent, obscene, threatening, invasive of another
          person's privacy rights or that is otherwise unlawful. You may not
          post content that infringes on the intellectual property rights of any
          other person or entity. You may not post any content that includes any
          computer virus or other code designed to disrupt, damage, or limit the
          functioning of any computer software or hardware.<h2>Compensation</h2>
        </p>
        <p>
          Some of the links on the Website may be &quot;affiliate links&quot;.
          This means if you click on the link and purchase an item, Website
          Operator will receive an affiliate commission.
          <h2>Indemnification and warranties</h2>
        </p>
        <p>
          While we have made every attempt to ensure that the information
          contained on the Website is correct, Website Operator is not
          responsible for any errors or omissions, or for the results obtained
          from the use of this information. All information on the Website is
          provided &quot;as is&quot;, with no guarantee of completeness,
          accuracy, timeliness or of the results obtained from the use of this
          information, and without warranty of any kind, express or implied. In
          no event will Website Operator be liable to you or anyone else for any
          decision made or action taken in reliance on the information on the
          Website or for any consequential, special or similar damages, even if
          advised of the possibility of such damages. Information on the Website
          is for general information purposes only and is not intended to
          provide legal, financial, medical, or any other type of professional
          advice. Please seek professional assistance should you require it.
          Furthermore, information contained on the Website and any pages linked
          to and from it are subject to change at any time and without warning.
        </p>
        <p>
          We reserve the right to modify this Disclaimer relating to the Website
          or Services at any time, effective upon posting of an updated version
          of this Disclaimer on the Website. When we do we will revise the
          updated date at the bottom of this page. Continued use of the Website
          after any such changes shall constitute your consent to such changes.
          Policy was created with{" "}
          <a
            style={{ color: "inherit" }}
            target="_blank"
            title="Create disclaimer"
            rel="noopener noreferrer" 
            href="https://www.websitepolicies.com/disclaimer-generator"
          >
            WebsitePolicies
          </a>
          .
        </p>
        <h2>Acceptance of this disclaimer</h2>
        <p>
          You acknowledge that you have read this Disclaimer and agree to all
          its terms and conditions. By accessing the Website you agree to be
          bound by this Disclaimer. If you do not agree to abide by the terms of
          this Disclaimer, you are not authorized to use or access the Website.
        </p>
        <h2>Contacting us</h2>
        <p>
          If you would like to contact us to understand more about this
          Disclaimer or wish to contact us concerning any matter relating to it,
          you may do so via the{" "}
          <a
            target="_blank"
            rel="noopener noreferrer" 
            href="https://www.devsurvival.com/contact/"
          >
            contact form
          </a>
        </p>
        <p>This document was last updated on March 26, 2020</p>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
